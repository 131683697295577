.type1 {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;

  .logo_list_item {
    width: 18%;
    height: 19rem;
    padding: 0.5rem;
    margin: 0.5rem;
    border: 1px solid #e7e7e6;
    background: #fff;
    border-radius: 8px;
    transition-duration: 0.15s;

    // &:hover {
    //   border: 1px solid #0070c9;
    //   transition: all 0.25s;

    //   .text {
    //     transition: all 0.25s;
    //     border-top: 0.125rem dashed #0070c9;
    //   }
    // }

    a {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .logo {
        position: relative;
        height: 180px;
        width: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1rem;
        text-align: center;
        color: #333;
        padding: 0.5rem;
        box-sizing: border-box;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }

        .maxoff {
          position: absolute;
          border: 1px solid #dadada;
          font-weight: 700;
          font-size: 0.75rem;
          line-height: 1rem;
          text-transform: uppercase;
          box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
            0 4px 6px -4px rgba(0, 0, 0, 0.1);
          background-color: #fff;
          padding: 0.5rem 1rem;
          border-radius: 1rem;
          right: 0.5rem;
          bottom: 0.5rem;
        }
      }

      .text {
        .border_top {
          height: 1px;
          background: #dadada;
          width: 75%;
          margin: 0 auto;
        }
        h3 {
          margin-top: 0.5rem;
          text-transform: uppercase;
          font-size: 0.75rem;
          line-height: 1rem;
          font-weight: 500;
        }

        p {
          margin-top: 0.5rem;
          font-size: 0.875rem;
          line-height: 1.25rem;
        }
      }
      .updated_at{
          font-size: 12px;
          color: #595959;
      }
    }

    &:hover {
      transform: scale(1.05);
    }
  }
}

@media screen and (max-width: 976px) {
  .type1 {
    .logo_list_item {
      width: 23%;
    }
  }
}

@media screen and (max-width: 826px) {
  .type1 {
    .logo_list_item {
      width: 30%;
    }
  }
}

@media screen and (max-width: 600px) {
  .type1 {
    justify-content: space-between;
    .logo_list_item {
      width: 45%;
    }
  }
}

@media screen and (max-width: 350px) {
  .type1 {
    .logo_list_item {
      width: 100%;
      margin: 0 0 10px 0;
    }
  }
}

@media screen and (max-width: 300px) {
  .type1 {
    .logo_list_item {
    }
  }
}
